angular.module('app')
    .controller('defaultTravelPolicyModalController',
        ["$scope", "$log", "$uibModalInstance", "message", function ($scope, $log, $uibModalInstance, message) {
            var vm = this;
            vm.message = message;
            vm.updateDefaultPolicy = updateDefaultPolicy;

            function updateDefaultPolicy() {
                $uibModalInstance.close();
            }

        }]);